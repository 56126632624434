
import { BIconChevronDown } from 'bootstrap-vue'
import { mapState } from 'vuex'
import NeedHelpPopup from '~/components/UI/need-help-popup.vue'
export default {
  components: {
    BIconChevronDown,
    NeedHelpPopup
  },
  async asyncData ({ $axios, $replaceSEO }) {
    // get data from pai

    const seo = await $axios.get('sitemaps/faq')
    let faqCategory = await $axios.get('faq_categories')
    faqCategory = faqCategory.data.data

    let faqContent = await $axios.get('faqs')
    faqContent = faqContent.data.data

    // create list
    const halftLength = Math.ceil(faqCategory.length / 2)
    const faqLeftSection = []
    const faqRightSeciion = []

    for (const index in faqCategory) {
      // section filter
      if ((parseInt(index) + 1) <= halftLength) {
        faqLeftSection.push({
          title: faqCategory[index].name,
          faqs: faqContent.filter(item => item.faq_category_id === faqCategory[index].id)
        })
      } else {
        faqRightSeciion.push({
          title: faqCategory[index].name,
          faqs: faqContent.filter(item => item.faq_category_id === faqCategory[index].id)
        })
      }
    }

    const faqSection = [
      faqLeftSection,
      faqRightSeciion
    ]

    return {
      faqSection,
      seo: $replaceSEO(seo.data.data, null, null, null, null, null)
    }
  },
  data () {
  },
  computed: {
    ...mapState(['userData'])
  },
  mounted () {
    // console.log(window.dataLayer)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: this.$route.name,
        path: `${this.$route.path}`,
        title: this.seo.title
      },
      user: {
        customerID: (this.userData) ? this.userData.uid : '',
        customerEmail: (this.userData) ? this.userData.email : ''
      }
    })
  },
  head () {
    return {
      title: this.seo.title,
      meta: [
        { hid: 'description', name: 'description', content: this.seo.description },
        { hid: 'keywords', name: 'keywords', content: this.seo.keywords }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.seo.canonical_url
        }
      ]
    }
  }
}
