import { render, staticRenderFns } from "./index.vue?vue&type=template&id=b02286c6&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=b02286c6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b02286c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/app/components/Banner/Banner.vue').default,SampleProduct: require('/app/components/SampleProduct/SampleProduct.vue').default,Product: require('/app/components/Product/Product.vue').default,ProductCategory: require('/app/components/Product/ProductCategory.vue').default,CustomerFeedback: require('/app/components/Customer/CustomerFeedback.vue').default,Promotion: require('/app/components/Promotion/Promotion.vue').default})
