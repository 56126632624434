
export default {
  data () {
    return {
      otpInputs: Array(6).fill('')
    }
  },
  computed: {
    isOtpComplete () {
      // ตรวจสอบว่า OTP ครบหรือยัง
      return this.otpInputs.every(input => input.length === 1)
    }
  },
  mounted () {
    this.$nextTick(() => {
      console.log('logggggg', this.$refs.otpInput0[0])
      this.$refs.otpInput0[0].focus() // Focus the first input after render
    })
  },
  methods: {
    handleInput (index) {
      const value = this.otpInputs[index].replace(/[^0-9]/g, '') // กรองเฉพาะตัวเลข
      this.$set(this.otpInputs, index, value)
      if (value.length === 1) {
        this.focusNext(index)
      }
    },
    focusNext (index) {
      if (this.otpInputs[index].length === 1 && index < this.otpInputs.length - 1) {
        this.$refs[`otpInput${index + 1}`][0].focus() // Fix here
      }
    },
    focusPrev (index) {
      if (this.otpInputs[index] === '' && index > 0) {
        const prevInput = this.$refs[`otpInput${index - 1}`]
        if (prevInput) {
          prevInput[0].focus() // Focus on the previous input if it exists
        }
      }
    },
    submitOtp () {
      const otp = this.otpInputs.join('')
      console.log('OTP:', otp)
      // ส่ง OTP ไปยัง server
    }
  }
}
