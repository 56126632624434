
import { mapMutations } from 'vuex'
import { BIconX } from 'bootstrap-vue'
export default {
  middleware: ['auth'],
  components: {
    BIconX
  },
  async asyncData ({ param, $axios, $store }) {
    try {
      const cartItem = await $axios.$get('my-orders?order_status=1')
      return { cartItem: cartItem.data, needLogin: false }
    } catch (error) {
      if (error && error.response && error.response.status !== 200) {
        return { cartItem: [], needLogin: true }
      }
    }
  },
  data () {
    return {
      cartItem: [],
      needLogin: false,
      selectedOrder: 0,
      fields: ['index'],
      checkAll: false,
      pendingDelete: null
    }
  },
  computed: {
    nItemSelected () {
      return this.cartItem.filter(x => x.selected).length
    }
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine']),
    async deleteCart (item) {
      const r = confirm(`คุณต้องการลบ Order ${item.order_code}`)
      if (r === true) {
        this.showLoading()
        const result = await this.$axios.$delete(`orders/${item.id}`)
        if (result.success === true) {
          const cartResult = await this.$axios.$get('my-orders?order_status=1')
          this.cartItem = cartResult.data
        }
        this.hideLoading()
      }
    },
    checkout () {
      if (this.selectedOrder !== 0) {
        this.$router.push(this.localeLocation(
          {
            name: 'checkout-id',
            params: {
              id: this.selectedOrder
            }
          }
        ))
      }
    },
    removeRowConfirm (index) {
      this.pendingDelete = index
      this.$refs['confirm-delete'].show()
    },
    removeRow () {
      this.cartItem.splice(this.pendingDelete, 1)
    },
    checkItem (e) {
      this.$nextTick(
        () => {
          this.checkAll = this.cartItem.every(x => x.selected)
        }
      )
    },
    check (e) {
      this.$nextTick(
        () => {
          this.cartItem = this.cartItem.map((x) => { x.selected = this.checkAll; return x })
        }
      )
    }
  }
}
